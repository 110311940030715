@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body,
html {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space for vertical scrollbar */
}

/* Hiển thị thanh cuộn ngang */
::-webkit-scrollbar-horizontal {
  height: 5px; /* Set the height of the horizontal scrollbar */
  background-color: #f1f1f1; /* Optional: Set the background color of the horizontal scrollbar */
}

/* Optional: customize the horizontal scrollbar thumb */
::-webkit-scrollbar-thumb:horizontal {
  background-color: #888; /* Set the color of the horizontal scrollbar thumb */
}

/* Optional: customize the horizontal scrollbar track */
::-webkit-scrollbar-track:horizontal {
  background-color: #f1f1f1; /* Set the color of the horizontal scrollbar track */
}
/*---------------------------------------------*/
a {
  /* font-family: Poppins-Regular; */
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #fff;
}
/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  /* font-family: Poppins-Regular; */
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #fff;
}
input:-moz-placeholder {
  color: #fff;
}
input::-moz-placeholder {
  color: #fff;
}
input:-ms-input-placeholder {
  color: #fff;
}

textarea::-webkit-input-placeholder {
  color: #fff;
}
textarea:-moz-placeholder {
  color: #fff;
}
textarea::-moz-placeholder {
  color: #fff;
}
textarea:-ms-input-placeholder {
  color: #fff;
}

label {
  margin: 0;
  display: block;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}
