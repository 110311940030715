.loginUser * {
  font-family: "Montserrat", sans-serif;
}

.loginUser {
  min-height: 100vh;
  display: flex;
}

.logo {
  width: 100px;
  aspect-ratio: 1/1;
  margin: auto;
}

.logoLogin {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 241px;
  aspect-ratio: 241/232;
}

.loginBackground {
  background-color: #f2f8ec;
  min-height: 100vh;
  width: 60vw;
  position: relative;
}

.registerSuccess {
  background-color: #f2f8ec;
  min-height: 100vh;
  width: 100vw;
  position: relative;
  color: #4fba48;
}

.registerSuccess > div {
  top: 15vh;
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: auto;
  text-align: center;
}

.registerSuccess .logoLogin {
  position: relative;
}

.registerSuccess button {
  /* border: 1px solid #2a9223; */
  color: white;
  background-color: #4fba48;
  border-radius: 5px;
  padding: 0.5rem 1rem;
}

.loginForm {
  background-color: white;
  width: 40vw;
  position: relative;
  padding: 8vw;
  margin: auto;
}

.loginForm.registerSuccess {
  width: 0;
}

.login__Form {
  /* position: absolute;
    top: 0;
    bottom: 0; */
  width: 24vw;
  height: fit-content;

  margin: auto;
}

.login__Form > p:not(:first-child) {
  font-size: 18px;
  color: #333333;
}

.login__FormHeading {
  font-size: 32px;
  font-weight: 700;
  color: black;
  margin-bottom: 0;
}

.login__backgroundImage {
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: url("../../../assets/img/login/login-background-image.png");
  background-size: 100% 100%;
  width: 50%;
  aspect-ratio: 431/280;
}

.login__forgotPassword {
  text-align: right;
  margin: 0.5rem 0;
}
.login__register {
  text-align: center;
  margin-top: 1rem;
}

.login__forgotPassword a,
.login__register a {
  color: #4fba48;
  text-decoration: none;
}

@media (orientation: portrait) {
  .loginBackground {
    display: none;
  }

  .loginForm {
    width: 100vw;
    height: fit-content;
    /* min-height: 100vh; */
    position: relative;
    padding: 10vw;
    margin: auto;
  }

  .login__Form {
    width: 80vw;
  }
}
