.landingFooter * {
  font-family: "Montserrat", sans-serif;
}

.landingFooter__register {
  background-color: #4fba48;
  padding: 5rem 15%;
}

.landingFooter__register * {
  color: white;
}

.register__note {
  width: 50vw;
  font-size: 18px;
  margin-bottom: 1rem;
  text-align: center;
}

.register__input {
  color: black;
  width: 400px;
  border-radius: 5px;
  padding: 5px 10px;
}

.register__input::placeholder,
.register__input::-ms-input-placeholder {
  color: rgb(73, 73, 73);
  opacity: 1;
}

.landingFooter__registerButton {
  background-color: #00662e;
  border-radius: 3rem;
  padding: 0.5rem 2rem;
  width: fit-content;
}

.footer {
  background-color: #f2f8ec;
  padding: 10vh 13vw;
  position: relative;
}

.bct__image {
  width: 150px;
}

.footerList {
  display: flex;
  justify-content: space-between;
  gap: 5vw;
  margin-top: 3rem;
}

.footerList > div {
  width: calc((100% - 10vw) / 3);
}

.footerList a {
  font-size: 18px;
  color: #383e32;
  text-decoration: none;
  margin: 0;
}

.footerList p {
  font-size: 16px;
  color: #383e32;
  text-decoration: none;
  margin: 0;
}

.footer__copyright {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  color: #383e32;
  font-size: 18px;
}

@media only screen and (max-width: 1400px) and (orientation: portrait) {
  .register__note {
    width: 60vw;
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .landingFooter__register {
    padding: 5rem 10vw;
  }

  .register__note {
    width: 80vw;
    font-size: 12px;
  }

  .footer {
    margin-bottom: 50px;
  }

  .footerList {
    margin-top: 1rem;
    flex-direction: column;
    justify-content: unset;
  }

  .footerList > div {
    width: 100%;
  }

  .footer__copyright {
    font-size: 9px;
    text-align: center;
  }
}
