.chinhSach {
  background-color: #f2f8ec;
  padding: 5vh 0;
}

.chinhSach__header {
  background-image: url("../../../assets/img/about-us/aboutus-section1-image.png");
  background-size: cover;
  background-position: center;
  min-height: 40vh;
  position: relative;
}

.chinhSach__header > h2 {
  position: absolute;
  color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.header__layer {
  background-color: #00000066;
  height: 100%;
  width: 100%;
  position: absolute;
}

.chinhSach__body {
  width: 70vw;
  margin: auto;
}

.chinhSach * {
  color: #046635;
}

/* .chinhSach__note p,
.chinhSach__note li {
  margin: 0;
  font-size: 12px;
  color: #046635;
} */

@media screen and (max-width: 600px) {
  .chinhSach__body {
    width: 90vw;
    margin: auto;
  }

  .chinhSach__header {
    min-height: 25vh;
  }

  .chinhSach__body * {
    text-align: justify;
  }
}
