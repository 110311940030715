.order * {
  font-family: "Montserrat", sans-serif;
}

.aboutUs__section1 {
  position: relative;
  min-height: 30vh;
  width: 100%;
  background-image: url("../../../assets/img/about-us/aboutus-section1-image.png");
  background-size: cover;
}

.section1__layer {
  background-color: #00000066;
  height: 100%;
  width: 100%;
  position: absolute;
}

.section1__content {
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70vw;
  height: fit-content;
  width: fit-content;
  margin: auto;
}

.section1__content h1 {
  width: fit-content;
}

.orderBody {
  padding: 5rem 10vw;
}

.userCash {
  display: flex;
  gap: 3%;
  margin-bottom: 5vh;
  justify-content: center;
}

.userCashData > p {
  margin: 0;
  color: black;
}

.userCashIcon {
  background-color: #d6d6d6;
  /* min-width: 50px;
  min-height: 50px; */
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  font-size: 18px;
}

.userCash > div {
  padding: 1rem;
  border-radius: 1rem;
  width: 20%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.orderSuccessBody {
  background-color: #f2f8ec;
  padding: 10vh 0;
}

.orderBody__tableHead th {
  background-color: #4fba48 !important;
  color: white !important;
  text-align: center;
  font-weight: 600;
  white-space: nowrap;
}

.orderForm {
  width: 40vw;
  margin: auto;
  margin-top: 3rem;
}

.orderForm > div {
  margin-bottom: 1rem;
}

.orderForm > div > label {
  margin-bottom: 0.3rem;
}

.productImage {
  width: 10vw;
  aspect-ratio: 1/1;
}

.orderInput {
  border: none;
  /* border: 1px solid rgb(204, 204, 204); */
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: rgb(245, 245, 245);
}

/* .orderInput:focus {
  border: 1px solid green !important;
} */

.orderButton {
  width: 100%;
  background-color: #4fba48;
  padding: 0.5rem 0;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
}

.orderBody__tableBody td {
  text-align: center;
  vertical-align: middle;
}

.orderError {
  border: 1px solid red !important;
}

.errorMessage {
  color: red;
  font-weight: 400;
  margin: 5px 0;
}
.quantity__input {
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  width: fit-content;
  min-width: 40px;
  width: 80px;
  padding: 5px;
  border-radius: 8px;
  padding-left: 10px;
}

@media screen and (max-width: 767px) {
  .orderForm {
    width: 100%;
  }

  .userCash {
    flex-direction: column;
    gap: 1rem;
  }

  .userCash > div {
    width: 100%;
  }
}
