.mobileAside {
    position: fixed;
    height: 100vh;
    width: 80vw;
    z-index: 90;
    bottom: 0;
    background-color: white;
    transition: all 0.3s;
}

.menuMobileAsideLayer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.5;
}

.mobileAside.open {
    left: 0;
}

.mobileAside.close {
    left: -100vw;
}

.mobileAsideBody {
    width: 90%;
    margin: auto;
}

.mobileAsideBody > div:first-child {
    background-color: rgb(236, 236, 236);
    padding: 5%;
    border-radius: 5px;
}

.mobileAsideBody li {
    margin-top: 0.8rem;
}

.mobileAsideBody a {
    text-decoration: none;
    color: black;
    font-size: 1rem;
    font-weight: 500;
}
