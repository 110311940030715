.aboutUs * {
  font-family: "Montserrat", sans-serif;
}

.aboutUs__section1 {
  position: relative;
  min-height: 40vh;
  width: 100%;
  background-image: url("../../../assets/img/about-us/aboutus-section1-image.png");
  background-size: cover;
  background-position: center;
}

.section1__layer {
  background-color: #00000066;
  height: 100%;
  width: 100%;
  position: absolute;
}

.section1__content {
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70vw;
  height: fit-content;
  margin: auto;
}

.section1__content h1,
.section1__content h2 {
  font-weight: 700;
  color: white;
  text-align: center;
}

.section1__content div {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.aboutUs__section2 {
  background-color: #f2f8ec;
  padding: 7rem 15vw;
}

.section2__content:not(:first-child) {
  margin-top: 3rem;
}

.section2__contentHeading {
  color: #046635;
  font-weight: 700;
  text-align: center;
}

.section2__contentBody {
  color: #046635;
  font-size: 18px;
  text-align: center;
}

@media screen and (max-width: 1500px) and (min-width: 600px) {
  .section1__content div {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .aboutUs__section1 {
    min-height: 30vh;
  }

  .section1__content div {
    font-size: 12px;
  }

  .section2__contentBody {
    text-align: justify;
  }

  .aboutUs__section2 {
    padding: 8vh 7.5vw;
  }
}
