.landingPage__login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4fba48;
}

.landingPage__login a {
    color: white !important;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    text-decoration: 1px solid rgb(238, 238, 238) underline;
}

.landingPage__login a:hover {
    text-decoration: none;
}

.closeLoginButton {
    background: #00000026;
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: white;
}

.landingPage__navbar {
    gap: 3vw;
    background-color: white;
    padding: 1.2rem 0;
    position: relative;
    height: 120px;
}

.navbar__left {
    right: 57.5vw;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    position: absolute;
    width: fit-content;
    display: flex;
    gap: 2.5vw;
}

.navbar__right {
    left: 57.5vw;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    position: absolute;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 2.5vw;
}

.navbar__left a,
.navbar__right a {
    color: #818286;
    text-decoration: none;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
}

.navbar__left a:hover,
.navbar__right a:hover {
    color: #4fba48;
}

.navbar__logo {
    width: 80px;
    aspect-ratio: 80/77;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.userName {
    color: #818286;
    margin-left: 10px;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
}

.registerButton {
    background-color: #00662e;
    padding: 0 30px;
    color: white;
    border-radius: 3rem;
}

@media only screen and (min-width: 2400px) {
    .navbar__left a,
    .navbar__right a,
    .userName {
        font-size: 1.2vw;
    }

    .landingPage__navbar {
        gap: 3vw;
        padding: 0.8vw 0;
    }

    .navbar__logo {
        width: 4vw;
    }
}

@media screen and (orientation: portrait) {
    .landingPage__navbar {
        padding: 0;
        height: 60px;
        display: flex;
        justify-content: space-between;
        padding: 7vw 3vw;
        align-items: center;
    }

    .navbar__logo {
        width: 10vw;
        aspect-ratio: 80/77;
        position: unset;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }

    .navbar__user {
        width: 10vw;
        aspect-ratio: 1/1;
        border-radius: 50%;
    }
}

@media screen and (max-width: 600px) {
    .landingPage__navbar {
        padding: 0;
        height: 60px;
        display: flex;
        justify-content: space-between;
        padding: 0 3vw;
        align-items: center;
    }

    .navbar__logo {
        width: 45px;
        aspect-ratio: 80/77;
        position: unset;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }

    .navbar__user {
        width: 40px;
        aspect-ratio: 1/1;
        border-radius: 50%;
    }
}
