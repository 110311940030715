.mobileMenu {
    width: 100vw;
    position: fixed;
    bottom: 0;
    background-color: white;
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px 10px 0 0;
    display: flex;
}

.selectedItem {
    background-color: #e4e4e4;
}

.menuItem {
    border-radius: 5px;
    width: 33.33%;
}

.menuItem__name {
    font-size: 11px;
    text-align: center;
}
