.wrapper {
  /* height: 100%; */
  min-height: 100vh;
  max-height: 100%;
  flex-direction: column;
  /* align-items: center; */
  padding-right: 10px;
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 10px;
  padding-left: 320px;
  background: #e5e5e5;
}

.wrapper__mobile {
  /* height: 100%; */
  min-height: 100vh;
  max-height: 100%;
  flex-direction: column;
  /* align-items: center; */
  padding-right: 10px;
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 10px;
  padding-left: 10px;
  background: #e5e5e5;
}

/* input css */
.formControl {
  position: relative;
  margin: 0.5rem 0 0.25rem;
}

.formIcon {
  position: absolute;
  left: 1.5rem;
  top: 0;
  bottom: 0;
  width: 1.3rem;
  height: 1.3rem;
  margin: auto;
  color: #afafaf;
}

.formInput {
  border-radius: 3rem;
  padding: 1rem 1.5rem 1rem 3.5rem;
  border: 1px solid #eeeeee !important;
  width: 100%;
  font-size: 14px;
}

.formInput:focus {
  border: 1px solid #eeeeee !important;
}

input::placeholder {
  color: #afafaf;
  opacity: 1; /* Firefox */
}

input::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #afafaf;
}

textarea::placeholder {
  color: #afafaf;
  opacity: 1; /* Firefox */
}

textarea::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #afafaf;
}

.formSubmitButton {
  /* width: fit-content; */
  background-color: #4fba48;
  color: white;
  padding: 1rem 2rem;
  width: 100%;
  border-radius: 3rem;
  font-size: 24px;
  margin-top: 1.5rem;
}
.orderButton {
  width: 100%;
  background-color: #4fba48;
  padding: 0.5rem 0;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
}
/* .MuiFormControl-root{
  height: 100%;
} */
.MuiInputBase-formControl {
  border-radius: 3rem !important;
}

.table-rows-mui th {
  background-color: #4fba48;
  font-weight: 700;
  color: white;
  white-space: nowrap;
}

.buttonGreen {
  background-color: #34c759 !important;
  white-space: nowrap;
}

.buttonGreen:hover {
  background-color: #30d158 !important;
}
.buttonRed {
  background-color: #ff3b30 !important;
  white-space: nowrap;
}

.buttonRed:hover {
  background-color: #ff453a !important;
}
.buttonBlue {
  background-color: #007aff !important;
  white-space: nowrap;
}

.buttonBlue:hover {
  background-color: #0a84ff !important;
}
.buttonYellow {
  background-color: #ff7300e7 !important;
  white-space: nowrap;
}

.buttonYellow:hover {
  background-color: #ff821de7 !important;
}

div:has(> table) {
  box-shadow: none !important;
}
