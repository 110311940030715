.aside {
  transition: width 0.5s;
  border-radius: 10px;
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: #4fba48;
  /* background: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%); */
  bottom: 10px;
  width: 300px;
  overflow-y: auto;
}

.aside__head {
  margin-top: 50px;
}
.aside__head__ava {
  /* border-radius: 50%; */
  width: 100px;
  height: 100px;
  /* border: 2px solid #fff; */
  padding: 2px;
  max-width: 100%;
  cursor: pointer;
}
.aside__head__name {
  margin-top: 15px;
  font-style: normal;
  font-weight: 700;
  /* font-size: 24px; */
  font-size: 17px;
  text-transform: uppercase;
  line-height: 29px;
  color: #fff;
}
.aside__body {
  margin-top: 60px;
}
.aside__body__list {
  list-style-type: none;
  text-decoration: none;
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
  text-transform: uppercase;
}
.aside__body__list__item {
  transition: all 0.2s;
  border-radius: 10px;
  margin: 0 15px;
}
.item__router {
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.aside__item__collapse {
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.sub__menu__list {
  padding-left: 1rem;
}
.sub__menu__list__mobile {
  margin-left: -30px;
}
.aside__item__collapse__active {
  background-color: rgba(0, 0, 0, 0.3);
  margin: 10px 15px;
  transition: all 0.4s;
}
.aside__item__collapse__mobile__active {
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
}
.sub__menu__list__item .item__router__active {
  /* color: #1fa64d; */
  color: white;
  font-weight: bold;
}
.sub__menu__list__item__mobile .item__router__active {
  /* color: white; */
  color: #1fa64d;
  font-weight: bold;
}
.item__router:hover {
  transition: all 0.4s;
  border-radius: 10px;
  margin: 0 15px;
}
.item__router__active {
  border-radius: 10px;
  background: #fff;
  /* color: white; */
  font-weight: 700;
  color: #1fa64d;
}
.item__router__active:hover {
  border-radius: 10px;
  background: #fff;
  color: #1fa64d;
  /* color: white; */
}
.item__links {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.item__dashboard {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.sub__menu__list__item {
  padding: 5px 15px;
  padding-left: 0;
}
.sub__menu__list__item:first-child {
  padding-top: 10px;
}
.sub__menu__list__item:last-child {
  padding-bottom: 0;
}
.sub__menu__list__item a {
  text-decoration: none;
  color: #fff;
}
.sub__menu__list__item__mobile {
  padding: 5px 15px;
  padding-left: 0;
}
.sub__menu__list__item__mobile:first-child {
  padding-top: 10px;
}
.sub__menu__list__item__mobile:last-child {
  padding-bottom: 0;
}
.sub__menu__list__item__mobile a {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
}
.sub__menu__list__item:hover {
  transition: all 0.4s;
  border-radius: 10px;
  margin: 0 15px;
}
.sub__menu__list__item__mobile:hover {
  transition: all 0.4s;
  border-radius: 10px;
}
.logout {
  position: absolute;
  width: calc(100% - 30px);
  border-radius: 10px;
  bottom: 20px;
}
.aside__mobile {
  /* width: 80px; */
  right: 0px;
  z-index: 1000;
  transition: height 0.5s;
  border-radius: 10px;
  position: fixed;
  top: 0px;
  left: 0px;
  /* background: linear-gradient(180deg, #1fa64d, #1fa6a6); */
  /* background: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%); */
  background-color: #4fba48;
  bottom: 0px;
  height: 0;
  overflow: hidden;
}
.aside__mobile__active {
  height: 100vh;
  overflow-y: auto;
}
.close__aside__btn {
  font-size: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.aside__mobile__head {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.aside__mobile__head__ava {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  padding: 2px;
  border-radius: 50%;
}
.aside__mobile__body {
  margin-top: 60px;
}
.aside__mobile__body__list {
  list-style: none;
  text-decoration: none;
  margin-bottom: 0;
  padding-left: 0;
}
.aside__mobile__body__list__item {
  transition: all 0.2s;
  border-radius: 10px;
  margin: 0 15px;
}
.item__router__mobile {
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.item__router__mobile:hover {
  transition: all 0.4s;
  border-radius: 10px;
  /* margin: 0 15px; */
}
.item__router__mobile__active {
  border-radius: 10px;
  background: #fff;
  color: #1fa64d;
}
