.shoppingCart {
    width: 400px;
    height: 100vh;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 100;
    transition: all 0.5s;
}

.shoppingCart.open {
    right: 0;
}

.shoppingCart.close {
    right: -500px;
}

.closeCartBody {
    position: absolute;
    left: -10px;
    top: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgb(240, 240, 240);
}

.cardBody__item {
    margin-top: 1.5rem;
    display: flex;
    padding: 0 5%;
    gap: 5%;
}

.cartItemImage {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.cartButton {
    padding: 0.2rem 1rem;
    border-radius: 5px;
    background-color: rgb(248, 248, 248) !important;
    color: black !important;
}

.cashout {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    padding: 5vh 5%;
}

.cashout > div {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 600px) {
    .shoppingCart {
        width: 300px;
    }

    .cashout > div {
        flex-direction: column;
    }
}
