.phapLy__header {
  background-image: url("../../../assets/img/about-us/aboutus-section1-image.png");
  background-size: cover;
  min-height: 40vh;
  position: relative;
  background-position: center;
}

.phapLy__header > h2 {
  position: absolute;
  color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.phapLy__layer {
  background-color: #00000066;
  height: 100%;
  width: 100%;
  position: absolute;
}

.phapLy__body {
  width: 100%;
  padding: 7.5vh 0;
  background-color: #f2f8ec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5vh;
}

.phapLy__body img {
  width: 40vw;
}

.phapLy__body h4,
.phapLy__body li {
  color: #046635;
}

.phapLy__body > div {
  width: 60vw;
  margin: auto;
}

.phapLy__body p {
  color: #046635;
}

.phapLy__body li {
  font-size: 18px;
  list-style-type: disc;
}

@media screen and (max-width: 600px) {
  .phapLy__body > div {
    width: 90vw;
    margin: auto;
  }

  .phapLy__header {
    min-height: 25vh;
  }

  .phapLy__body * {
    text-align: justify;
  }

  .phapLy__header > h2 {
    max-width: 80vw;
    text-align: center;
  }
}

@media screen and (min-width: 2400px) {
}
