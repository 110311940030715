.header {
  display: flex;
  align-items: center;
  /* box-shadow: rgb(0, 0, 0) 0px 4px 4px 0px; */
  color: rgb(33, 37, 41);
  line-height: 24px;
  justify-content: space-between;
}
.header__left {
  padding-left: 30px;
}
.header__left img:nth-child(2) {
  margin-left: 15px;
}
.header__right {
  display: flex;
  padding-right: 30px;
}
.nav__list {
  display: flex;
  text-decoration: none;
  list-style: none;
  align-items: center;
  margin: 0;
}
.nav__list__item {
  font-family: Roboto;
  font-weight: 700;
  line-height: 24px;
  padding: 28px 24px;
  transition: all 0.3s;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}
.nav__list__item:hover {
  color: rgba(24, 122, 57, 0.47);
  border-bottom-color: #1fa64d;
}
.header__custom {
  margin-top: 10px;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  /* justify-content: space-between; */
  margin-bottom: 10px;
  width: 100%;
  min-height: 24px;
  background-color: #fff;
  padding: 5px 20px;
  border-radius: 8px;
  /* padding-right: 10px; */
  /* background: #e5e5e5; */
}
.header__custom__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(20% - 10px);
  background-color: #fff;
  border-radius: 13px;
  box-shadow: 1.5px 1.5px #ccc;
  height: 94px;
}
.header__mobile {
  display: block;
  position: absolute;
  top: 2.5px;
  right: 2.5px;
  left: 2.5px;
  height: 50px;
  background: #fff;
  border-radius: 8px;
}
.header__mobile__bg {
  box-shadow: 0 22px 12px -15px rgba(0, 0, 0, 0.16);
  /* border: 1px solid #e9e9e9 */
}
.header__mobile:hover {
  background: rgba(248, 255, 242, 0.712);
}
.header__mobile__bar {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav__list__mobile {
  list-style: none;
  text-decoration: none;
  margin-bottom: 0;
  padding-left: 0;
}
.nav__list__mobile__item {
  transition: all 0.2s;
  border-radius: 10px;
  margin: 0 15px;
}
.mobile__menu__main {
  top: 60px;
  background: linear-gradient(180deg, #1fa64d, #1fa6a6, #fff);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  position: fixed;
  transition: width 2s;
}
.item__link {
  display: flex;
  align-items: center;
  color: #fff;
  padding-left: 10px;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.item__link:hover {
  transition: all 0.4s;
  border-radius: 10px;
  margin: 0 15px;
}
