.landingPage * {
  font-family: "Montserrat", sans-serif;
}

.landingPage {
  position: relative;
}

.landingPage__section1 {
  background: url("../../assets/img/landing-page/section-1-banner.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  padding: 10vh 5vw 15vh;
}

.cardOverLay {
  position: fixed;
  background-color: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 10;
}

.section1__body {
  width: 55vw;
  text-align: center;
}

.section1__body > * {
  font-family: "Montserrat", sans-serif;
  color: #383e32;
}

.section1__body h1 {
  color: #046635;
  font-weight: 700;
}

.section1__body p {
  font-size: 44px;
}

.section1__image {
  width: 484px;
  aspect-ratio: 484/200;
}

.section1__description {
  width: 35vw;
  font-size: 28px;
  margin: 3.5rem auto;
}

.section1__button {
  background-color: #00662e;
  color: white;
  padding: 0.5rem 2rem;
  font-size: 23px;
  border-radius: 3rem;
  transition: all 0.5s;
}

.section1__button:hover {
  transition: all 0.5s;
  background-color: #003b1b;
  color: rgb(216, 216, 216);
}

.landingPage__section2 {
  background: url("../../assets/img/landing-page/section2-background.webp");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 40vh 0;
}

.section2__text1 {
  margin-top: 3vh;
}

.landingPage__section2 * {
  color: white;
  font-family: "Montserrat", sans-serif;
}

.landingPage__section2 > div {
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  margin: auto;
}

.section2__text1,
.section2__text2 {
  font-weight: 500;
}

.section2__quote2,
.section2__quote1 {
  font-weight: 700;
}

.landingPage__section3 {
  padding: 3.5vw 5vw;
  min-height: 50vh;
  display: flex;
  gap: 8vw;
  align-items: center;
}

.section3__carousel {
  width: 30vw;
  position: relative;
}

.section3__carouselItem {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.section3__itemInfomation * {
  font-family: "Montserrat", sans-serif;
}

.section3__itemName {
  font-size: 55px;
  color: #818286;
}

.section3__shoppingIcon {
  width: 33px;
  aspect-ratio: 1/1;
}

.section3__itemPrice {
  font-size: 52px;
  color: #046635;
  font-weight: 700;
}

.section3__orderButton {
  font-weight: 700;
  color: white;
  font-size: 30px;
  background-color: #4fba48;
  padding: 0.5rem 2rem;
  border-radius: 3rem;
  display: flex;
  gap: 20px;
  align-items: center;
  transition: all 0.5s;
}

.section3__orderButton:hover {
  background-color: #439c3c;
}

.readMoreButton {
  background-color: #00662e;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  width: fit-content;
  font-size: 16px;
}

.landingPage__section4 {
  padding-bottom: 6rem;
}

.section4__heading {
  font-size: 55px;
  text-align: center;
}

.section4__content,
.section10__content {
  padding: 0 6rem;
  display: flex;
  gap: 1.5%;
}

.section4__contentItem,
.section10__contentItem {
  width: calc(94% / 5);
}

.section4__contentItemImage,
.section10__contentItemImage {
  width: 100%;
  aspect-ratio: 320/411;
  object-fit: cover;
  transition: all 0.5s;
}

.section4__contentItemImage:hover,
.section10__contentItemImage:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(1.05);
  cursor: pointer;
}

.section4__contentItemName,
.section10__contentItemName {
  font-size: 28px;
  color: #383e32;
  text-align: center;
  padding: 0 2.5rem;
  margin-top: 1rem;
}

.section10__contentItemName {
  white-space: nowrap;
  padding: unset;
}

.section4__contentItemDescription,
.section10__contentItemReview {
  color: #818286;
  text-align: justify;
  padding: 0 0.5rem;
}

.landingPage__section5 {
  background-color: #f2f8ec;
  padding: 4rem 13%;
}

.section5__heading,
.section7__heading,
.section10__heading {
  color: #046635;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem;
}

.section5__content p,
.section7__content * {
  color: #046635;
  font-size: 18px;
  text-align: justify;
  margin-bottom: 0;
  font-weight: 400;
}

.section7__content li {
  list-style-type: disc;
}

.section5__content div,
.section7__content div {
  margin-bottom: 2rem;
}

.landingPage__section6 {
  display: flex;
  gap: 2vw;
  justify-content: center;
  padding: 8rem 0;
}

.landingPage__section6 > div > div {
  display: flex;
  gap: 2vw;
}

.landingPage__section6 h2 {
  color: #046635;
  font-weight: 700;
}

.landingPage__section6 ul,
.landingPage__section6 li {
  list-style-type: disc;
  color: #777777;
  font-size: 23px;
}

.section6__image {
  width: 519px;
  aspect-ratio: 519/392;
}

.landingPage__section7 {
  background-color: #f2f8ec;
  padding: 4rem 20%;
}

.landingPage__section8 {
  background-color: #4fba48;
  display: flex;
  justify-content: center;
  gap: 3vw;
  padding: 2rem 20%;
  align-items: center;
}

.landingPage__section8 * {
  color: white;
}

.landingPage__section8 ul {
  padding: 0 10vw;
}

.landingPage__section8 li {
  font-size: 25px;
  font-weight: 700;
  white-space: nowrap;
  list-style-type: disc;
}

.section8__heading {
  font-weight: 700;
  text-align: center;
}

.section8__image {
  width: 373px;
  aspect-ratio: 373/476;
  object-fit: cover;
}

.section9__list {
  display: flex;
  flex-wrap: wrap;
  padding: 10rem 15vw;
  justify-content: center;
  gap: 3vw;
}

.section9__listItem {
  width: 20vw;
}

.section9__listName {
  text-align: center;
  color: #383e32;
  font-size: 30px;
  white-space: nowrap;
}

.section9__listImage {
  width: 100px;
  max-height: 100px;
  /* aspect-ratio: 1/1; */
}

.landingPage__section10 {
  padding-bottom: 10rem;
}

@media only screen and (max-width: 1500px) and (min-width: 600px) {
  .landingPage__section1 {
    background-position: center;
    padding-left: 0;
    padding-right: 0;
  }

  .section1__body h1 {
    color: #046635;
  }

  .section1__body p {
    font-size: 2.4vw;
  }

  .section1__description {
    font-size: 1.4vw;
  }

  .section2__text1,
  .section2__text2 {
    font-weight: 500;
  }

  .section3__itemName {
    font-size: 2.2vw;
    margin: 0;
  }

  .section3__itemPrice {
    font-size: 2.8vw;
  }

  .section4__contentItemName,
  .section10__contentItemName {
    padding: 0;
    font-size: 1.6vw;
  }

  .landingPage__section6 {
    gap: 5vw;
  }

  .landingPage__section6 li {
    font-size: 1.6vw;
  }

  .section6__image {
    width: 30vw;
  }

  .section9__listName {
    font-size: 1.8vw;
  }
}

@media only screen and (min-width: 2400px) {
  .landingPage__section1 {
    background-size: cover;
    position: relative;
    padding: 40vh 5vw;
  }

  .section1__body {
    height: fit-content;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .landingPage__section2 {
    padding: 30vh 0;
  }
}

@media all and (min-width: 767px) and (max-width: 1400px) and (orientation: portrait) {
  .landingPage__section1 {
    padding: 8% 0 10%;
  }

  .section1__description {
    font-size: 13px;
  }

  .landingPage__section2 {
    padding: 15vh 0;
    min-height: unset;
    background-size: cover;
  }

  .landingPage__section3 {
    padding: 2vh 5vw;
    min-height: 35vh;
  }

  .landingPage__section4 {
    padding: 4rem 5vw 3rem;
  }

  .section4__list {
    display: flex;
    gap: 5vw;
    align-items: center;
  }

  .section4__contentItemName {
    font-weight: 700;
  }

  .section4__contentItemImage {
    width: 25vw;
    aspect-ratio: 1/1;
    border-radius: 1rem;
  }

  .section4__carouselDescription,
  .section10__carouselDescription {
    min-height: 10rem;
    font-size: 13px;
    min-width: 60vw;
  }

  .landingPage__section6 {
    padding: 5rem 10vw;
  }

  .landingPage__section6 h2 {
    text-align: center;
  }

  .landingPage__section7 {
    padding: 3rem 10vw;
  }

  .section7__content div,
  .section7__content li,
  .section7__content p {
    font-size: 13px;
  }

  .landingPage__section8 {
    flex-direction: column-reverse;
  }

  .landingPage__section8 li {
    font-size: 14px;
  }

  .section8__image {
    width: 100%;
    aspect-ratio: 2/1.2;
    border-radius: 1rem;
    align-items: center;
  }

  .section9__list {
    padding: 5rem 15vw;
  }

  .landingPage__section10 {
    padding-bottom: 5rem;
  }

  .section10__content {
    gap: 5vw;
    align-items: center;
  }

  .section10__contentItemImage {
    width: 15vw;
    border-radius: 0.5rem;
  }

  .hiddenContent {
    display: none;
    transition: all 1s ease-out;
  }
}

@media only screen and (max-width: 600px) {
  .landingPage__section1 {
    background-position: left;
    padding: 10vh 0;
  }

  .section4__contentItemImage:hover,
  .section10__contentItemImage:hover {
    transform: unset;
  }

  .section1__body {
    width: 90vw;
    margin: auto;
    position: relative;
  }

  .section1__body p {
    font-size: 20px;
  }

  .section1__image {
    width: 100%;
  }

  .section1__description {
    width: 100%;
    font-size: 14px;
  }

  .landingPage__section2 {
    padding: 20vh 0;
    background-size: cover;
  }

  .landingPage__section2 h2 {
    font-size: 10px;
  }

  .landingPage__section2 h3 {
    font-size: 12px;
  }

  .section2__text1,
  .section2__text2 {
    margin: 0;
  }

  .section2__text1 {
    margin-top: 1rem;
  }

  .landingPage__section3 {
    flex-direction: column;
  }

  .section3__carousel,
  .section3__itemInfomation {
    width: 90vw;
  }

  .section3__itemName {
    font-size: 20px;
  }

  .section3__itemPrice,
  .section3__orderButton {
    font-size: 24px;
  }

  .landingPage__section4 {
    padding: 0;
    margin-top: 5rem;
  }

  .section4__heading {
    font-size: 20px;
  }

  .section4__contentItemImage {
    width: 100vw;
  }
  .section4__contentItemDescription {
    color: white;
    font-size: 12px;
  }

  .section4__carouselDescription,
  .section10__carouselDescription {
    font-size: 12px;
    color: black;
    min-height: 10rem;
    padding: 0.5rem 3vw;
    text-align: justify;
  }

  .section4__contentItemName {
    color: black;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
  }

  .landingPage__section5 {
    padding: 2rem 3vw;
  }

  .section5__heading {
    font-size: 28px;
  }

  .hiddenContent {
    display: none;
    transition: all 1s ease-out;
  }

  .section5__content p {
    font-size: 12px;
  }

  .landingPage__section6 {
    flex-direction: column-reverse;
    padding: 3rem 0;
  }

  .section6__image {
    width: 90vw;
    margin: auto;
  }

  .landingPage__section6 h2 {
    text-align: center;
  }

  .landingPage__section6 > div {
    width: fit-content;
    margin: auto;
  }

  .landingPage__section6 li {
    font-size: 12px;
  }

  .landingPage__section7 {
    padding: 2rem 3vw;
  }

  .section7__content * {
    font-size: 12px;
  }

  .landingPage__section8 {
    flex-direction: column-reverse;
  }

  .landingPage__section8 {
    padding: 2rem 3vw;
  }

  .section8__image {
    width: 90vw;
    aspect-ratio: 4/3;
    border-radius: 2rem;
  }

  .landingPage__section8 ul {
    padding: unset;
    width: fit-content;
    margin: auto;
  }

  .landingPage__section8 li {
    font-size: 11px;
  }

  .section9__list {
    padding: 2rem 3vw;
  }

  .section9__listItem {
    width: 45vw;
  }

  .section9__listName {
    font-size: 16px;
  }

  .landingPage__section10 {
    padding: 0;
    margin-top: 3rem;
  }

  .section10__content {
    width: 90vw;
    margin: auto;
    padding: unset;
    flex-direction: column;
  }
}
